import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notFound from "../../assets/notFound.svg";

const NoProductsFound = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto text-center mb-16">
            <h1 className="relative z-[2] mb-0 pb-0 pt-20 font-heading text-lg font-medium leading-loose text-body-font">
                {t("no_products_message")}
            </h1>
            <img
                src={notFound}
                className="mx-auto w-80"
                alt="No products found"
            />
            <Link
                to="/"
                className="transform rounded-lg bg-primary-green px-5 py-3 text-white transition duration-300 ease-in-out hover:bg-[#466d6d] focus:outline-none"
            >
                {t("go_home")}
            </Link>
        </div>
    );
};

export default NoProductsFound;
