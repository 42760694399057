import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useFilter } from "../../contexts/FilterContext.jsx";
import { SORT_OPTIONS, SORT_ORDER } from "../../utils/constants.js";

// SortTab Component
const SortTab = ({ label, isActive, onClick }) => (
    <button
        onClick={onClick}
        className={`font-lato inline-flex w-full cursor-pointer items-center justify-center rounded-t border-b-2 py-3 text-base font-normal leading-normal sm:w-auto sm:px-6 ${isActive ? "border-primary-orange bg-[#f28f3b26] text-primary-orange" : "border-transparent bg-gray-50 text-secondary-font hover:text-primary-orange"}`}
        aria-pressed={isActive}
    >
        {label}
    </button>
);

SortTab.propTypes = {
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

// Mobile SortTab Component
const MobileSortTab = ({ label, isActive, onClick }) => (
    <li
        onClick={onClick}
        className={`mx-2 my-2 cursor-pointer hover:text-primary-orange md:mx-1.5 ${isActive ? "text-primary-orange" : ""}`}
    >
        {label}
    </li>
);

MobileSortTab.propTypes = {
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default function SortTabs({ isSortVisible, onSortClick }) {
    // for translation
    const { t } = useTranslation();

    const { state, dispatch } = useFilter();

    const handleSort = useCallback(
        (sortBy, order) => {
            dispatch({ type: "SET_SORT_BY", payload: sortBy });
            dispatch({ type: "SET_ORDER", payload: order });
            dispatch({ type: "SET_PAGE", payload: 1 });
        },
        [dispatch],
    );

    const sortOptions = [
        {
            label: t("relevance"),
            sortBy: SORT_OPTIONS.RELEVANCE,
            order: SORT_ORDER.DESC,
        },
        {
            label: t("recentlyAdded"),
            sortBy: SORT_OPTIONS.RECENTLY,
            order: SORT_ORDER.DESC,
        },
        {
            label: t("dealsAndOffers"),
            sortBy: SORT_OPTIONS.DEALS,
            order: SORT_ORDER.DESC,
        },
        {
            label: t("lowestPrice"),
            sortBy: SORT_OPTIONS.LOWEST_PRICE,
            order: SORT_ORDER.ASC,
        },
    ];

    return (
        <>
            <div className="mb-10 flex flex-wrap justify-center border-b-2 border-neutral-200 bg-gray-50 max-lg:hidden">
                {sortOptions.map((option) => (
                    <SortTab
                        key={option.sortBy}
                        label={option.label}
                        isActive={state.sortBy === option.sortBy}
                        onClick={() => handleSort(option.sortBy, option.order)}
                    />
                ))}
            </div>

            {/* Mobile Sort Tabs */}
            <div
                className={`fixed start-0 top-0 z-[9999] w-screen shadow-2xl lg:hidden ${isSortVisible ? "flex" : "hidden"}`}
            >
                <div className="z-50 max-h-screen w-[320px] overflow-y-auto bg-white">
                    {/* Header */}
                    <div className="my-2 rounded-lg border-b-2 bg-white px-4">
                        {/* sort title and close btn section */}
                        <div className="flex items-center justify-between p-4">
                            <div className="flex items-center">
                                <svg
                                    viewBox="0 0 28 22"
                                    fill="none"
                                    className="me-2 h-5 w-5 cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.052 21.4893C6.75245 21.8134 6.33504 22 5.89307 22C5.45111 22 5.0337 21.8134 4.73414 21.4893L0.412716 16.775C-0.171659 16.1366 -0.132374 15.1397 0.51093 14.5554C1.15423 13.971 2.1462 14.0103 2.73057 14.6536L4.32164 16.3871V1.57143C4.32164 0.702232 5.02388 0 5.89307 0C6.76227 0 7.4645 0.702232 7.4645 1.57143V16.3871L9.05557 14.6487C9.63995 14.0103 10.6368 13.9661 11.2752 14.5504C11.9136 15.1348 11.9578 16.1317 11.3734 16.7701L7.052 21.4844V21.4893ZM15.3216 0H16.8931C17.7623 0 18.4645 0.702232 18.4645 1.57143C18.4645 2.44062 17.7623 3.14286 16.8931 3.14286H15.3216C14.4524 3.14286 13.7502 2.44062 13.7502 1.57143C13.7502 0.702232 14.4524 0 15.3216 0ZM15.3216 6.28571H20.0359C20.9051 6.28571 21.6074 6.98795 21.6074 7.85714C21.6074 8.72634 20.9051 9.42857 20.0359 9.42857H15.3216C14.4524 9.42857 13.7502 8.72634 13.7502 7.85714C13.7502 6.98795 14.4524 6.28571 15.3216 6.28571ZM15.3216 12.5714H23.1788C24.048 12.5714 24.7502 13.2737 24.7502 14.1429C24.7502 15.0121 24.048 15.7143 23.1788 15.7143H15.3216C14.4524 15.7143 13.7502 15.0121 13.7502 14.1429C13.7502 13.2737 14.4524 12.5714 15.3216 12.5714ZM15.3216 18.8571H26.3216C27.1908 18.8571 27.8931 19.5594 27.8931 20.4286C27.8931 21.2978 27.1908 22 26.3216 22H15.3216C14.4524 22 13.7502 21.2978 13.7502 20.4286C13.7502 19.5594 14.4524 18.8571 15.3216 18.8571Z"
                                        fill="#F28F3B"
                                    />
                                </svg>

                                <span className="font-heading text-lg font-medium leading-loose text-primary-green">
                                    {t("sortBy")}
                                </span>
                            </div>

                            {/* close btn */}
                            <svg
                                className="cursor-pointer text-orange-400"
                                height="1.4rem"
                                width="1.4rem"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                                onClick={onSortClick}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </div>
                    </div>
                    {/* Header */}

                    {/* Sort Options */}
                    <ul className="font-lato p-3 text-base font-medium text-body-font">
                        {sortOptions.map((option) => (
                            <MobileSortTab
                                key={option.sortBy}
                                label={option.label}
                                isActive={state.sortBy === option.sortBy}
                                onClick={() => {
                                    handleSort(option.sortBy, option.order);
                                    onSortClick();
                                    window.scrollTo(0, 0);
                                }}
                            />
                        ))}
                    </ul>
                </div>
                <div
                    onClick={onSortClick}
                    className="h-screen flex-1 bg-black opacity-50"
                ></div>
            </div>
        </>
    );
}

SortTabs.propTypes = {
    isSortVisible: PropTypes.bool.isRequired,
    onSortClick: PropTypes.func.isRequired,
};
