import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

/**
 * SEO component that defines metadata for the page.
 *
 * @param {string} title - The title of the page.
 * @param {string} description - The description of the page.
 * @param {string} name - The name of the author.
 * @param {string} type - The type of the page.
 * @returns {JSX.Element}
 */

function SEO({ title, description, name = "arkhass", type = "website" }) {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* End standard metadata tags */}
            {/* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* End Facebook tags */}
            {/* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {/* End Twitter tags */}
        </Helmet>
    );
}

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
};

// eslint-disable-next-line react-refresh/only-export-components
export default SEO;
