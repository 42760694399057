import PropTypes from 'prop-types';


const ToggleFilterButton = ({ onSortClick, onFilterClick, activeButton }) => {

    return (
        <div
            title="toggle filter"
            className="fixed bottom-4 end-4 z-50 flex items-center justify-center rounded-3xl bg-primary-orange px-4 py-2 text-white shadow-lg outline-none lg:hidden"
        >
            <svg
                width="28"
                xmlns="http://www.w3.org/2000/svg"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                className="h-6 w-6 cursor-pointer"
                onClick={onSortClick}
            >
                <path
                    d="M7.052 21.4893C6.75245 21.8134 6.33504 22 5.89307 22C5.45111 22 5.0337 21.8134 4.73414 21.4893L0.412716 16.775C-0.171659 16.1366 -0.132374 15.1397 0.51093 14.5554C1.15423 13.971 2.1462 14.0103 2.73057 14.6536L4.32164 16.3871V1.57143C4.32164 0.702232 5.02388 0 5.89307 0C6.76227 0 7.4645 0.702232 7.4645 1.57143V16.3871L9.05557 14.6487C9.63995 14.0103 10.6368 13.9661 11.2752 14.5504C11.9136 15.1348 11.9578 16.1317 11.3734 16.7701L7.052 21.4844V21.4893ZM15.3216 0H16.8931C17.7623 0 18.4645 0.702232 18.4645 1.57143C18.4645 2.44062 17.7623 3.14286 16.8931 3.14286H15.3216C14.4524 3.14286 13.7502 2.44062 13.7502 1.57143C13.7502 0.702232 14.4524 0 15.3216 0ZM15.3216 6.28571H20.0359C20.9051 6.28571 21.6074 6.98795 21.6074 7.85714C21.6074 8.72634 20.9051 9.42857 20.0359 9.42857H15.3216C14.4524 9.42857 13.7502 8.72634 13.7502 7.85714C13.7502 6.98795 14.4524 6.28571 15.3216 6.28571ZM15.3216 12.5714H23.1788C24.048 12.5714 24.7502 13.2737 24.7502 14.1429C24.7502 15.0121 24.048 15.7143 23.1788 15.7143H15.3216C14.4524 15.7143 13.7502 15.0121 13.7502 14.1429C13.7502 13.2737 14.4524 12.5714 15.3216 12.5714ZM15.3216 18.8571H26.3216C27.1908 18.8571 27.8931 19.5594 27.8931 20.4286C27.8931 21.2978 27.1908 22 26.3216 22H15.3216C14.4524 22 13.7502 21.2978 13.7502 20.4286C13.7502 19.5594 14.4524 18.8571 15.3216 18.8571Z"
                    fill={`${activeButton === "sort" ? "#588B8B" : "white"}`}
                />
            </svg>

            <div className="mx-4 h-6 border-e-2 border-white"></div>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke={`${activeButton === "filter" ? "#588B8B" : "white"}`}
                className={`h-6 w-6 cursor-pointer ${activeButton === "filter" ? "text-gray-500" : ""}`}
                onClick={onFilterClick}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707L15 12.414V18a1 1 0 01-.553.894l-4 2A1 1 0 019 20v-7.586L3.293 6.707A1 1 0 013 6V4z"
                ></path>
            </svg>
        </div>
    );
};

ToggleFilterButton.propTypes = {
    onSortClick: PropTypes.func.isRequired,
    onFilterClick: PropTypes.func.isRequired,
    activeButton: PropTypes.string,
};

export default ToggleFilterButton;
