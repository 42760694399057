import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFilter } from "../contexts/FilterContext.jsx";
import Loading from "../components/common/Loading.jsx";
import LoadingError from "../components/common/LoadingError.jsx";
import { useStores } from "../hooks/useFetch.js";
import SEO from "../components/common/SEO.jsx";
import { getLocalizedData } from "../utils/getLocalizedData.js";
import StoreCard from "../components/common/StoreCard.jsx";

export default function StoresPage() {
    // for translation
    const { t, i18n } = useTranslation();
    // Get the current language
    const language = i18n.resolvedLanguage;

    const {
        data: dataStores,
        isLoading: storesLoading,
        storesError,
    } = useStores();

    // State and dispatch from context
    // for store section
    const { dispatch } = useFilter();
    const navigate = useNavigate();

    // to select store in productsList page
    const handleStoreClick = (storeId) => {
        dispatch({ type: "SET_SELECTED_STORES", payload: [storeId] });
        navigate("/product");
    };

    // Check if any data is loading
    if (storesLoading) {
        return <Loading />;
    }
    if (storesError) {
        return <LoadingError errorMessage={storesError.message} />;
    }

    // Get localized product data
    const stores = dataStores
        ? getLocalizedData(dataStores, language)
        : undefined;

    // SEO configuration
    const page = {
        title: t("stores_page.title"),
        description: t("stores_page.description"),
    };

    return (
        <section className="container mx-auto mb-8 mt-2 bg-white px-4 py-8">
            <SEO title={page.title} description={page.description} />

            <h1 className="font-heading my-10 text-center text-5xl font-medium leading-10 text-body-font">
                {t("stores")}
            </h1>

            <div className="font-heading text-center text-lg font-medium leading-normal text-secondary-font sm:text-right">
                {t("stores_page.stores_count", { count: stores.length })}
            </div>

            <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {stores.map((store) => (
                    <StoreCard
                        key={store.id}
                        onClick={() => handleStoreClick(store.id)}
                        store={store}
                    />
                ))}
            </div>
        </section>
    );
}
