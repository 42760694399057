import { useState, useEffect } from "react";
import Slider from "rc-slider";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import { useFilter } from "../../contexts/FilterContext.jsx";

const MultiSlider = ({ range = [0, 100000], apiRange, onChange }) => {
    // for translation
    const { t } = useTranslation();

    const { state } = useFilter();

    const [localRange, setLocalRange] = useState(range);

    useEffect(() => {
        setLocalRange(range);
    }, [range]);

    const handleSliderChange = (newRange) => {
        setLocalRange(newRange);
    };

    const handleSliderAfterChange = (newRange) => {
        onChange(newRange);
    };

    const handleInputChange = (index, value) => {
        const newRange = [...localRange];
        newRange[index] = value ? parseInt(value, 10) : 0;
        setLocalRange(newRange);
    };
    
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            onChange(localRange);
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <Slider
                range
                min={apiRange?.minPrice || state.defaultPriceRange[0]}
                max={apiRange?.maxPrice || state.defaultPriceRange[1]}
                value={localRange}
                onChange={handleSliderChange}
                onChangeComplete={handleSliderAfterChange}
                styles={{
                    track: { backgroundColor: "#4D848E" },
                    rail: { backgroundColor: "#C5D5D9" },
                    active: { backgroundColor: "#4D848E" },
                    thumb: {
                        borderColor: "#4D848E",
                        backgroundColor: "#4D848E",
                        opacity: 0.7
                    },
                    handle: {
                        borderColor: "#4D848E",
                        backgroundColor: "#4D848E",
                        opacity: 0.7
                    }
                }}
                ariaLabelForHandle={t("price_range_slider")}
            />
            <div className="mt-2 flex flex-wrap justify-between gap-2">
                <input
                    type="text"
                    value={localRange[0]}
                    onChange={(e) => handleInputChange(0, e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={()=> onChange(localRange)}
                    className="w-20 rounded border text-center outline-primary-green"
                    placeholder={t("min_range")}
                    aria-label={t("min_range")}
                />
                <span>-</span>
                <input
                    type="text"
                    value={localRange[1]}
                    onChange={(e) => handleInputChange(1, e.target.value)}
                    onKeyDown={handleKeyDown} // Detect Enter key
                    onBlur={()=> onChange(localRange)}
                    className="w-20 rounded border text-center outline-primary-green"
                    placeholder={t("max_range")}
                    aria-label={t("max_range")}
                />
            </div>
        </div>
    );
};

MultiSlider.propTypes = {
    range: PropTypes.arrayOf(PropTypes.number).isRequired,
    apiRange: PropTypes.shape({
        minPrice: PropTypes.number,
        maxPrice: PropTypes.number
    }),
    onChange: PropTypes.func.isRequired
};

export default MultiSlider;
