import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { BASE_STORES_URL } from "../../utils/constants.js";
import { useCompare } from "../../contexts/CompareContext";

const CompareCard = ({ product }) => {
    // Destructuring the product object to get the necessary properties
    const { product_id, store_name, photo_url, name, store_logo, isSelected } = product;

    // Compare context
    const { toggleCompare, dispatch } = useCompare();

    return (
        <div className="relative">
        {/* min-h-[16rem] md:min-h-[22.5rem] */}
            <div className="relative flex h-full  flex-col justify-between rounded-lg border border-gray-200 bg-white p-2 text-body-font shadow">
                {/* image and discount section */}
                <div className="relative h-36 md:h-48">
                    {/* image */}
                    <Link
                        to={`/product/${product_id}`}
                        className="relative flex h-full w-full cursor-pointer items-center justify-center border-b border-gray-200"
                    >
                        <img
                            src={photo_url}
                            alt={name}
                            loading="lazy"
                            className="h-full w-11/12 object-contain transition-transform duration-500 hover:scale-105"
                            height="192"
                            width="160"
                        />
                    </Link>

                    {/* check and close buttons */}
                    {/* close button */}
                    <div className="absolute end-0 top-0">
                        <button
                            type="button"
                            onClick={() => toggleCompare(product)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* check box */}
                    <div className="absolute start-0 top-0">
                        <input
                            id={`product-${product_id}`}
                            value={name}
                            type="checkbox"
                            checked={isSelected}                            
                            onChange={() => dispatch({ type: "TOGGLE_SELECTION", payload: { product_id } })}
                            name="product"
                            
                            className="h-6 w-6 cursor-pointer rounded-sm border-primary-green bg-primary-green text-primary-green outline-none focus:ring-primary-green"
                        />
                    </div>

                    {/* store image */}
                    <img
                        src={BASE_STORES_URL + store_logo}
                        alt={store_name}
                        className="absolute bottom-0 start-0 h-7 w-14 rounded bg-white object-contain shadow"
                    />
                </div>

                {/* product name and compare button section */}
                <div className="flex items-center justify-between gap-1 my-2">
                    <Link
                        className="font-heading m-0 line-clamp-2 flex-1 cursor-pointer text-base font-medium leading-normal text-body-font sm:m-2 md:text-lg"
                        to={`/product/${product_id}`}
                    >
                        {name}
                    </Link>
                </div>
            </div>
        </div>
    );
};

CompareCard.propTypes = {
    product: PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        photo_url: PropTypes.string.isRequired,
        store_name: PropTypes.string.isRequired,
        store_logo: PropTypes.string.isRequired,
        category_name: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
    }).isRequired,
};

export default CompareCard;
