import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StoreCard from "../common/StoreCard.jsx";

const MainStores = ({ stores, onStoreClick, onSeeMoreClick }) => {
    const { t } = useTranslation();

    return (
        <section className="container mx-auto bg-white px-4 py-8">
            <div className="mb-8 flex items-center">
                <p className="me-10 text-2xl font-medium leading-10 text-primary-green">
                    {t("main_stores.header")}
                </p>

                <button
                    onClick={onSeeMoreClick}
                    className="font-lato text-base font-normal leading-normal text-primary-orange"
                >
                    {t("see_more")}
                </button>
            </div>

            <div className="flex flex-nowrap gap-6 overflow-x-auto pb-4 lg:justify-evenly">
                {stores.map((store) => (
                    <StoreCard
                        key={store.id}
                        onClick={() => onStoreClick(store.id)}
                        store={store}
                        className="flex-shrink-0"
                    />
                ))}
            </div>
        </section>
    );
};

MainStores.propTypes = {
    stores: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            logo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onStoreClick: PropTypes.func.isRequired,
    onSeeMoreClick: PropTypes.func.isRequired,
};

export default MainStores;
