const getLocalizedData = (data, language) => {
    if (!data || typeof data !== "object") return data;

    const suffix = language === "ar" ? "_ar" : "_en";

    // const localizedData = {};
    // initialize localizedData according to the type of data if it object or array
    let localizedData;
    if (Array.isArray(data)) {
        localizedData = [];
    } else if (typeof data === "object") {
        localizedData = {};
    }

    Object.keys(data).forEach((key) => {
        if (key.endsWith(suffix)) {
            const localizedKey = key.replace(suffix, "");
            localizedData[localizedKey] = data[key];
        } else if (Array.isArray(data[key])) {
            localizedData[key] = data[key].map((item) =>
                getLocalizedData(item, language),
            );
        } else if (typeof data[key] === "object" && data[key] !== null) {
            // Recursively handle nested objects
            localizedData[key] = getLocalizedData(data[key], language);
        } else {
            localizedData[key] = data[key];
        }
    });
    return localizedData;
};

const getLocalizedField = (data, field, langKey = "en") => {
    return data[`${field}_${langKey}`];
};

/* use the getLocalizedField:
const name = getLocalizedField(product, "name", "en");
*/

export { getLocalizedData, getLocalizedField };
