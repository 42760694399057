import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFilter } from "../../contexts/FilterContext.jsx";
import PropTypes from 'prop-types';

const ResetSearchOnHome = ({ children }) => {
    const { dispatch } = useFilter();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!pathname.startsWith("/product")) {
            dispatch({ type: "RESET_SEARCH" });
            dispatch({ type: "RESET_FILTERS" });
            // console.log('page filter reset');
        }
        
    }, [pathname, dispatch]);

    return children;
};

ResetSearchOnHome.propTypes = {
    children: PropTypes.node,
};

export default ResetSearchOnHome;
