import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import "./styles/tailwind.css";
import App from "./App.jsx";
import { BASE_URL } from "./utils/constants";
import "./i18n";
import { FilterProvider } from "./contexts/FilterContext";
import * as Sentry from "@sentry/react";

axios.defaults.baseURL = BASE_URL;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <FilterProvider>
                <App />
            </FilterProvider>
        </Suspense>
    </React.StrictMode>,
);
