import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PRODUCTS_LIMIT } from "../../utils/constants.js";

const ProductInfo = ({ search, page, total }) => {
    const { t } = useTranslation();

    return (
        <div className="mb-4 grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-4">
            <div className="p-2 font-heading text-lg font-semibold leading-10 text-body-font md:p-1 md:pb-0">
                {search}
            </div>
            <div className="p-2 font-heading text-base font-normal leading-tight text-neutral-500 sm:text-end">
                {t("showing")}
                <span className="font-medium text-primary-green">
                    {(page - 1) * PRODUCTS_LIMIT + 1}
                </span>{" "}
                {t("to")}
                <span className="font-medium text-primary-green">
                    {Math.min(page * PRODUCTS_LIMIT, total)}
                </span>{" "}
                {t("of")}
                <span className="font-medium text-primary-orange">
                    {total}
                </span>{" "}
                {t("results")}
            </div>
        </div>
    );
};

ProductInfo.propTypes = {
    search: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default ProductInfo;
