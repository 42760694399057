import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    // for translation
    const { t, i18n } = useTranslation();

    // Function to determine which page numbers to display
    const getPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 3;

        if (totalPages <= maxPageNumbersToShow) {
            // Show all page numbers if total pages is less than or equal to maxPageNumbersToShow
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            let startPage = Math.max(
                1,
                currentPage - Math.floor(maxPageNumbersToShow / 2),
            );
            let endPage = startPage + maxPageNumbersToShow - 1;

            // Adjust the startPage and endPage to ensure they are within the valid range
            if (endPage > totalPages) {
                startPage = totalPages - maxPageNumbersToShow + 1;
                endPage = totalPages;
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            // Add ellipsis and last page if needed
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pages.push("...");
                }
                pages.push(totalPages);
            }

            // Add ellipsis and first page if needed
            if (startPage > 1) {
                if (startPage > 2) {
                    pages.unshift("...");
                }
                pages.unshift(1);
            }
        }
        return pages;
    };

    const pages = getPageNumbers();
    const isRTL = i18n.dir() === "rtl";

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-1 py-3 md:px-4">
            {/* Mobile view: Previous and Next buttons */}
            <div className="flex flex-1 justify-between sm:hidden">
                <button
                    type="button"
                    onClick={() => onPageChange(currentPage - 1)}
                    className={`font-heading relative inline-flex items-center justify-center rounded border-2 border-orange-border bg-white py-3 px-2 text-sm font-semibold leading-8 text-body-font hover:bg-gray-50 ${
                        currentPage <= 1 ? "invisible" : "visible"
                    }`}
                >
                    <svg
                        className="mx-1 h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"

                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d={
                                isRTL
                                    ? "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    : "M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            }
                            clipRule="evenodd"
                        />
                    </svg>
                    {/* {t("previous")} */}
                </button>
                <button
                    type="button"
                    onClick={() => onPageChange(currentPage + 1)}
                    className={`font-heading relative inline-flex items-center justify-center rounded border-2 border-orange-border bg-white px-2 py-3 text-sm font-semibold leading-8 text-body-font hover:bg-gray-50 ${
                        currentPage >= totalPages ? "invisible" : "visible"
                    }`}
                >
                    {/* {t("next")} */}
                    <svg
                        className="mx-1 h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d={
                                isRTL
                                    ? "M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    : "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            }
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {/* Desktop view: Page number buttons */}
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
                <div>
                    <nav
                        className="isolate inline-flex gap-3 -space-x-px rounded-md"
                        aria-label="Pagination"
                    >
                        {/* Previous button */}
                        <button
                            type="button"
                            onClick={() => onPageChange(currentPage - 1)}
                            className={`font-heading relative inline-flex h-10 w-10 items-center justify-center rounded border border-orange-border bg-white text-sm font-semibold leading-8 text-body-font hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                                currentPage <= 1 ? "invisible" : "visible"
                            }`}
                            aria-hidden={currentPage <= 1}
                        >
                            <span className="sr-only">{t("previous")}</span>
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d={
                                        isRTL
                                            ? "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                            : "M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    }
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        {/* Page number buttons */}
                        {pages.map((page, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={() =>
                                    typeof page === "number" &&
                                    onPageChange(page)
                                }
                                disabled={page === "..."}
                                className={`font-heading relative inline-flex h-10 w-10 items-center justify-center rounded border border-orange-border text-sm font-semibold leading-8 ${
                                    currentPage === page
                                        ? "bg-primary-orange text-white hover:bg-orange-400"
                                        : "bg-white text-body-font hover:bg-gray-50"
                                } focus:z-20 focus:outline-offset-0`}
                            >
                                {page}
                            </button>
                        ))}
                        {/* Next button */}
                        <button
                            type="button"
                            onClick={() => onPageChange(currentPage + 1)}
                            className={`font-heading relative inline-flex h-10 w-10 items-center justify-center rounded border border-orange-border bg-white text-sm font-semibold leading-8 text-body-font hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                                currentPage >= totalPages ? "invisible" : "visible"
                            }`}
                            aria-hidden={currentPage >= totalPages}
                        >
                            <span className="sr-only">{t("next")}</span>
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d={
                                        isRTL
                                            ? "M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                            : "M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    }
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
