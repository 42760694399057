import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Filters from "../components/productsList/Filters.jsx";
import ProductList from "../components/productsList/ProductList.jsx";
import ToggleFilterButton from "../components/productsList/ToggleFilterButton.jsx";
import SEO from "../components/common/SEO.jsx";

const ProductsListPage = () => {
    const { t } = useTranslation();

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isSortVisible, setIsSortVisible] = useState(false);

    const toggleFilterVisibility = useCallback(() => {
        if (isSortVisible) {
            setIsSortVisible(false);
        }
        setIsFilterVisible((prev) => !prev);
    }, [isSortVisible]);

    const toggleSortVisibility = useCallback(() => {
        if (isFilterVisible) {
            setIsFilterVisible(false);
        }
        setIsSortVisible((prev) => !prev);
    }, [isFilterVisible]);

    // SEO metadata
    const page = {
        title: t("products_list_page.title"),
        description: t("products_list_page.description"),
    };

    return (
        <div className="mx-6 sm:mx-4">
            <SEO title={page.title} description={page.description} />
            <ToggleFilterButton
                onSortClick={toggleSortVisibility}
                onFilterClick={toggleFilterVisibility}
                activeButton={
                    isFilterVisible ? "filter" : isSortVisible ? "sort" : null
                }
            />
            <div
                // className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-12"
                className="mt-[1px] grid grid-cols-1 gap-4 lg:grid-cols-12"
            >
                <Filters
                    isVisible={isFilterVisible}
                    onFilterClick={toggleFilterVisibility}
                />
                <ProductList
                    isSortVisible={isSortVisible}
                    onSortClick={toggleSortVisibility}
                />
            </div>
        </div>
    );
};

export default ProductsListPage;
