// works for react-create-app project
// export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";
// works for vite app
// export const BASE_URL = import.meta.env.VITE_BASE_URL || "https://server.arkhass.com/";
export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const BASE_URL_IMAGE = `${BASE_URL}/public/images/`;
export const BASE_OFFERS_URL = `${BASE_URL_IMAGE}/offers/`;
export const BASE_CATEGORIES_URL = `${BASE_URL_IMAGE}/categories/`;
export const BASE_STORES_URL = `${BASE_URL_IMAGE}/stores/`;

export const PRODUCTS_LIMIT = 12;

export const SORT_OPTIONS = {
    RELEVANCE: "relevance",
    RECENTLY: "updatedAt", // DESC
    DEALS: "discount", // DESC
    LOWEST_PRICE: "price", // ASC
};

export const SORT_ORDER = {
    ASC: "asc",
    DESC: "desc",
};

// typesense
export const TYPESENSE_URL = `${import.meta.env.VITE_TYPESENSE_PROTOCOL}://${import.meta.env.VITE_TYPESENSE_HOST}:${import.meta.env.VITE_TYPESENSE_PORT}`;
export const TYPESENSE_API_KEY = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY;
export const TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION;

export const MAX_SELECTABLE_PRODUCTS = 4;
