import PropTypes from "prop-types";
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// custom style
import "../../styles/custom-swiper.css";
import { BASE_OFFERS_URL } from "../../utils/constants";

function HomeSlider({ offers, language }) {
    return (
        <Swiper
            key={language} // Changing the key forces re-render
            // install Swiper modules
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={50}
            navigation
            autoplay={{
                delay: 3000,
                disableOnInteraction: true,
            }}
            loop={true}
            pagination={{ clickable: true, dynamicBullets: true }}
            lazy={"true"}
            autoHeight={true}
        >
            {offers.map((offer, index) => (
                <SwiperSlide key={index}>
                    <a href={offer.url} target="_blank">
                        <img
                            // src={offer.photo}
                            src={BASE_OFFERS_URL + offer.photo}
                            alt={`${offer.store.name} offers`}
                            // className="h-auto max-h-[80vh] w-full rounded shadow-lg"
                            // h-[40rem]
                            className=" h-auto w-full rounded shadow-lg"
                            loading="lazy"
                        />
                    </a>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

HomeSlider.propTypes = {
    language: PropTypes.string,
    offers: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            photo: PropTypes.string.isRequired,
            store: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }),
    ).isRequired,
};

export default HomeSlider;
