import PropTypes from "prop-types";

// Define the `TranslateImage` component
const TranslateImage = ({ language = "en" }) => {
    const isArabic = language === "ar";

    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Translate icon"
            className="mx-2 max-md:h-6 max-md:w-6"
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M35.5059 40C35.1847 39.7854 34.832 39.6011 34.548 39.3532C33.3446 38.2969 32.154 37.2278 30.9761 36.1459C30.877 36.0461 30.7544 35.9672 30.6172 35.915C30.4799 35.8628 30.3314 35.8384 30.1824 35.8438C26.785 35.8542 23.3877 35.8511 19.9903 35.85C18.0047 35.85 16.877 34.8448 16.877 33.0729C16.877 27.9702 16.877 22.866 16.877 17.7604C16.877 16.4584 17.6917 15.4823 19.0162 15.2021C19.3002 15.1501 19.59 15.1277 19.8797 15.1354C25.5866 15.1313 31.2927 15.1313 36.998 15.1354C38.8602 15.1354 39.9775 16.1417 39.9787 17.8125C39.9787 22.8646 39.9484 27.9167 39.9985 32.9677C40.0136 34.5354 39.0732 35.7271 37.1668 35.8782C37.1668 36.7417 37.1365 37.6115 37.1761 38.4823C37.2098 39.2292 36.8979 39.7323 36.1146 40.0011L35.5059 40ZM35.8004 38.8334C35.8246 38.7897 35.8437 38.744 35.8574 38.6969C35.8574 37.6684 35.8574 36.64 35.8574 35.6115C35.8574 34.8188 36.0087 34.6823 36.8909 34.6813C38.1374 34.6813 38.6693 34.2073 38.6693 33.0938V17.8979C38.6693 16.7688 38.1584 16.3094 36.9049 16.3094H19.9647C18.7089 16.3094 18.184 16.7761 18.184 17.8917V33.0865C18.184 34.1907 18.731 34.6813 19.9635 34.6813C23.2305 34.6813 26.4987 34.7167 29.7645 34.6604C30.7759 34.6438 31.5034 34.8823 32.1865 35.5594C33.3248 36.6854 34.5667 37.7261 35.8004 38.8334Z"
                    className={
                        isArabic ? "fill-primary-orange" : "fill-primary-green"
                    }
                />
                <path
                    d="M20.8691 0C20.9448 0.0338184 21.0225 0.0637286 21.1019 0.0895833C22.3368 0.440625 23.1131 1.33958 23.1177 2.50729C23.1305 6.02292 23.1236 9.53854 23.1177 13.0542C23.1177 13.5146 22.8687 13.7698 22.4567 13.7688C22.0447 13.7677 21.8142 13.5229 21.8142 13.049C21.8142 9.62535 21.8142 6.20104 21.8142 2.77604C21.8142 1.63958 21.2858 1.17188 20.0067 1.17188H3.06072C1.86193 1.17188 1.32422 1.64792 1.32305 2.70833C1.32305 7.8 1.32305 12.891 1.32305 17.9813C1.32305 19.074 1.84796 19.5365 3.08166 19.5438C3.16896 19.5438 3.25625 19.5438 3.34237 19.5438C3.89521 19.5563 4.13031 19.7635 4.13264 20.2729C4.13846 21.3917 4.13264 22.5104 4.13264 23.7271C4.30257 23.5896 4.41546 23.5052 4.51905 23.4146C5.83423 22.234 7.14746 21.0535 8.45876 19.8729C8.57042 19.7633 8.70732 19.6766 8.86003 19.6189C9.01274 19.5611 9.17762 19.5337 9.3433 19.5385C11.0565 19.551 12.7697 19.5438 14.483 19.5438C14.5563 19.5438 14.6285 19.5438 14.7006 19.5438C15.1056 19.5729 15.3757 19.8115 15.3745 20.1344C15.3733 20.4573 15.0987 20.7021 14.6925 20.7083C13.9371 20.7208 13.1794 20.7135 12.4276 20.7135C11.5267 20.7135 10.6271 20.7 9.72738 20.724C9.50512 20.7357 9.29402 20.815 9.12915 20.949C7.81514 22.1052 6.51742 23.2844 5.21039 24.4458C4.39568 25.1635 3.17128 24.9135 2.88264 23.9802C2.83939 23.8024 2.82179 23.6204 2.83027 23.4385C2.82212 22.5667 2.82212 21.6948 2.81747 20.8219C2.81747 20.799 2.79652 20.776 2.77906 20.7406C2.56025 20.7073 2.33213 20.6833 2.11099 20.6365C1.54113 20.5144 1.03056 20.2309 0.655356 19.8281C0.280154 19.4252 0.0603975 18.9247 0.028825 18.401C0.0160223 18.2063 0.0160223 18.0104 0.0160223 17.8125C0.0160223 12.8802 0.0404637 7.94479 -0.00027188 3.01042C-0.0119106 1.63125 0.442 0.489583 2.31351 0H20.8691Z"
                    className={
                        !isArabic ? "fill-primary-orange" : "fill-primary-green"
                    }
                />
                <path
                    d="M16.456 14.5344C16.2756 14.6917 16.1243 14.9074 15.9078 14.9938C15.5936 15.1199 15.3003 14.9876 15.1478 14.7063C15.0093 14.4501 14.9337 14.1657 14.787 13.9136C14.7496 13.8536 14.6979 13.8018 14.6355 13.7618C14.5732 13.7218 14.5017 13.6947 14.4262 13.6824C12.512 13.6678 10.5967 13.6678 8.68017 13.6824C8.59819 13.6947 8.52053 13.7239 8.45328 13.7676C8.38603 13.8114 8.33103 13.8685 8.2926 13.9344C7.9993 14.5594 7.74791 15.1938 7.47789 15.823C7.29749 16.2334 6.98208 16.3844 6.60731 16.2574C6.23254 16.1303 6.08939 15.8136 6.25815 15.4188C7.73239 11.9466 9.20663 8.47437 10.6809 5.00215C10.8357 4.63757 11.122 4.42194 11.5654 4.4209C12.0088 4.41986 12.2963 4.62924 12.4511 4.99799C13.7322 8.05076 15.0132 11.1022 16.2942 14.1522C16.3291 14.2506 16.3575 14.3507 16.3792 14.4522L16.456 14.5344ZM14.1993 12.4813L11.5608 6.21153L8.89898 12.4813H14.1993Z"
                    className={
                        !isArabic ? "fill-primary-orange" : "fill-primary-green"
                    }
                />
                <path
                    d="M26.8308 23.1605C26.186 22.4875 25.8706 21.7448 26.0929 20.875C26.3326 19.9323 26.9797 19.3011 28.004 19.023C29.0282 18.7448 29.9267 18.9709 30.7007 19.6146C31.0603 19.9136 31.0975 20.2834 30.817 20.5375C30.5365 20.7917 30.1827 20.7605 29.8173 20.474C29.2353 20.0198 28.5603 19.9532 27.9807 20.3073C27.7124 20.4672 27.511 20.7022 27.408 20.9756C27.305 21.2489 27.3062 21.5451 27.4115 21.8178C27.6176 22.3563 28.1331 22.6803 28.8594 22.6959C29.5263 22.7105 30.1979 22.674 30.8613 22.7188C30.9833 22.7356 31.1001 22.7751 31.2041 22.8346C31.3081 22.8942 31.3971 22.9725 31.4653 23.0646C31.5526 23.2 31.5037 23.5032 31.3815 23.6271C31.2442 23.7688 30.9614 23.849 30.7356 23.8615C30.128 23.8959 29.5158 23.8615 28.9083 23.8886C28.1046 23.925 27.3336 24.1848 26.7025 24.6318C26.0715 25.0787 25.6119 25.6906 25.3876 26.3823C25.1608 27.0657 25.1724 27.7948 25.4207 28.4721C25.6691 29.1494 26.1425 29.7428 26.7776 30.173C27.4127 30.6032 28.1794 30.8498 28.9752 30.8797C29.771 30.9097 30.5581 30.7216 31.2314 30.3407C31.3419 30.2782 31.4444 30.2042 31.5561 30.1438C31.9053 29.9542 32.2696 30.0073 32.4779 30.275C32.6862 30.5428 32.6327 30.8698 32.2998 31.098C31.3516 31.7594 30.1711 32.0969 28.97 32.05C27.8465 32.0169 26.7649 31.6606 25.8857 31.0338C25.0064 30.4071 24.3763 29.5433 24.0888 28.5708C23.8014 27.5982 23.8719 26.5686 24.2899 25.6347C24.708 24.7008 25.4512 23.9124 26.4094 23.3865C26.5421 23.3167 26.676 23.2448 26.8308 23.1605Z"
                    className={
                        isArabic ? "fill-primary-orange" : "fill-primary-green"
                    }
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

// Define prop types for the component
TranslateImage.propTypes = {
    language: PropTypes.string.isRequired,
};

export default TranslateImage;
