import { createContext, useReducer, useContext, useCallback } from "react";
import PropTypes from "prop-types";

// Create a context to hold the state and dispatch function
const CompareContext = createContext();

/*
    Reducer function to manage state changes
    it takes the current state and an action 
    and returns a new state based on the action type
*/
const compareReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_COMPARE": {
            const productExists = state.products.some(
                (product) => product.product_id === action.payload.product_id,
            );

            // if the product exists, remove it from the list
            // else add it to the list
            return productExists
                ? {
                      ...state,
                      products: state.products.filter(
                          (product) =>
                              product.product_id !== action.payload.product_id,
                      ),
                  }
                : {
                      ...state,
                      products: [
                          ...state.products,
                          { ...action.payload, isSelected: false },
                      ],
                  };
        }
        case "TOGGLE_SELECTION": {
            return {
                ...state,
                products: state.products.map((product) =>
                    product.product_id === action.payload.product_id
                        ? { ...product, isSelected: !product.isSelected }
                        : product,
                ),
            };
        }
        default:
            return state;
    }
};

// initialize the state object
const initialState = {
    products: [],
};

// Provider component to provide state and dispatch function to children
export const CompareProvider = ({ children }) => {
    // Use useReducer hook to manage state, It accepts a reducer function and an initial state
    const [state, dispatch] = useReducer(compareReducer, initialState);

    // Provide state and dispatch function to children components via context
    const toggleCompare = useCallback((product) => {
        dispatch({ type: "TOGGLE_COMPARE", payload: product });
    }, []);

    return (
        <CompareContext.Provider value={{ state, toggleCompare, dispatch }}>
            {children}
        </CompareContext.Provider>
    );
};

// Custom hook to access state and dispatch function from context
// eslint-disable-next-line react-refresh/only-export-components
export const useCompare = () => {
    const context = useContext(CompareContext);
    if (!context) {
        throw new Error("useCompare must be used within a CompareProvider");
    }
    return context;
};

// Prop types for the CompareProvider component
CompareProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
