import { useTranslation } from "react-i18next";
import TranslateImage from "./TranslateImage.jsx";
import { useFilter } from "../../contexts/FilterContext";
import PropTypes from "prop-types";

const LanguageSwitcher = ({ setIsNavOpen }) => {
    // for translation
    const { t, i18n } = useTranslation();

    // Get the current language
    const language = i18n.resolvedLanguage;

    const { dispatch } = useFilter();

    const toggleLanguage = () => {
        const newLang = language === "en" ? "ar" : "en";
        i18n.changeLanguage(newLang);
        dispatch({ type: "SET_LANGUAGE", payload: newLang });
        // Check if setIsNavOpen is provided before calling it
        if (setIsNavOpen) {
            setIsNavOpen(false);
        }
    };

    return (
        <div
            className="flex cursor-pointer items-center"
            onClick={toggleLanguage}
        >
            <TranslateImage language={language} />
            <p className="text-primary-green">{t("language")}</p>
        </div>
    );
};

LanguageSwitcher.propTypes = {
    setIsNavOpen: PropTypes.func,
};

export default LanguageSwitcher;
