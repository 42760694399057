import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SEO from "../components/common/SEO.jsx";
import error404 from "../assets/error404.svg";

const NotFoundPage = () => {
    const { t } = useTranslation();

    const page = {
        title: t("not_found_page.title"),
        description: t("not_found_page.description"),
    };

    return (
        <div className="container mx-auto text-center py-16">
            <SEO title={page.title} description={page.description} />

            <h1 className="font-heading text-4xl md:text-5xl font-bold text-primary-green mb-4">
                {t("not_found_page.heading")}
            </h1>
            <p className="font-lato text-xl text-body-font mb-8">
                {t("not_found_page.message")}
            </p>
            <img
                src={error404}
                alt="404 Not Found"
                className="mx-auto w-64 md:w-80 mb-8"
            />
            <Link
                to="/"
                className="font-heading inline-block transform rounded-lg bg-primary-orange px-6 py-3 text-white text-lg transition duration-300 ease-in-out hover:bg-[#f0780f] focus:outline-none"
            >
                {t("go_home")}
            </Link>
        </div>
    );
};

export default NotFoundPage;